import { useObjectFormField, useFormFieldSnapshot, snapshot } from '@kaliber/forms'
import { LinkUnderline } from '/features/buildingBlocks/Link'
import { LoaderSmall } from '/features/buildingBlocks/Loader'
import { Button, ButtonGhost } from '/features/buildingBlocks/Button'
import { FormFieldText, FormFieldEmail, FormFieldTextarea, FormFieldCheckboxConsent, FormFieldFile, formFieldRenderers, FormFieldCheckboxBasic } from '/features/buildingBlocks/FormField'
import { Stepper } from '/features/buildingBlocks/Stepper'
import { useLocationMatch } from '@kaliber/routing'
import { useTranslate } from '/machinery/I18n'
import { routeMap } from '/routeMap'

import styles from './FormFields.css'

export function FormFieldsWithSteps({ form, extensions, isLoading, screeningQuestions, resumeFieldRequired, enablePortfolioField, onScrollToForm }) {
  const [step, setStep] = React.useState('default')

  return (
    <div className={styles.componentWithSteps}>
      <Stepper
        steps={2}
        step={step === 'default' ? 1 : 2}
        layoutClassName={styles.stepperLayout}
      />

      {step === 'default'
        ? (
          <DefaultStep
            field={form.fields.default}
            onNextStep={() => setStep('screeningQuestions')}
            {...{ extensions, resumeFieldRequired, enablePortfolioField, onScrollToForm }}
          />
        ) : (
          <ScreeningQuestionsStep
            onPreviousStep={() => setStep('default')}
            {...{ screeningQuestions, form, isLoading, onScrollToForm }}
          />
        )}
    </div>
  )
}

export function FormFields({ form, isLoading, extensions, resumeFieldRequired, enablePortfolioField, onScrollToForm }) {
  const { fields } = form

  return (
    <>
      <DefaultFields {...{ extensions, resumeFieldRequired, enablePortfolioField }} field={fields.default} />
      <ConsentCheckboxes {...{ fields }} />
      <SubmitButton {...{ isLoading }} onClick={onScrollToForm} />
      <ValidationErrors formOrfield={form} />
    </>
  )
}

function DefaultStep({ field, extensions, onNextStep, resumeFieldRequired, enablePortfolioField, onScrollToForm }) {
  const { __ } = useTranslate()

  return (
    <>
      <DefaultFields {...{ extensions, field, resumeFieldRequired, enablePortfolioField }} />

      <Button dataX="go-to-next-step" onClick={handleNextStepClick}>
        {__`form-next-step`}
      </Button>

      <ValidationErrors formOrfield={field} />
    </>
  )

  function handleNextStepClick() {
    field.setSubmitted(true)

    if (snapshot.get(field).invalid) return

    onNextStep()
    onScrollToForm()
  }
}

function ScreeningQuestionsStep({ form, screeningQuestions, isLoading, onPreviousStep, onScrollToForm }) {
  const { __ } = useTranslate()
  const { fields } = form

  return (
    <>
      {screeningQuestions.map(question => {
        const [field] = question.fields
        const Component = formFieldRenderers[field.type]

        return field.type === 'INFORMATION'
          ? <Information key={question.id} message={question.label} />
          : (
            <Component
              key={question.id}
              field={fields.answers.fields[question.id]}
              options={field.values?.map(x => ({ label: x.label, value: x.id }))}
              required={field.required}
              label={question.label}
              placeholder={field.type === 'SINGLE_SELECT'
                ? __`application-form-select-default-placeholder`
                : __`form-field-text-field-placeholder`
              }
            />
          )
      })}

      <ConsentCheckboxes {...{ fields }} />

      <div className={styles.buttonContainer}>
        <ButtonGhost
          dataX="previous-step"
          onClick={onPreviousStep}
          disabled={isLoading}
        >
          {__`form-previous-step`}
        </ButtonGhost>

        <SubmitButton {...{ isLoading }} onClick={onScrollToForm} />
      </div>

      <ValidationErrors formOrfield={form} />
    </>
  )
}

function DefaultFields({ field, extensions, resumeFieldRequired, enablePortfolioField }) {
  const { __ } = useTranslate()
  const fields = field.fields

  return (
    <>
      <FormFieldEmail
        label={__`form-field-e-mail`}
        field={fields.email}
        placeholder={__`form-field-e-mail-placeholder`}
        required
      />
      <div className={styles.twoFields}>
        <FormFieldText
          label={__`form-field-firstName`}
          field={fields.firstName}
          placeholder={__`form-field-firstName-placeholder`}
          required
        />
        <FormFieldText
          label={__`form-field-lastName`}
          field={fields.lastName}
          placeholder={__`form-field-lastName-placeholder`}
          required
        />
      </div>
      <FormFieldText
        label={__`form-field-phone`}
        field={fields.phoneNumber}
        placeholder={__`form-field-phone-placeholder`}
        required
      />
      <FormFieldText
        label={__`form-field-link`}
        field={fields.link}
        placeholder={__`form-field-link-placeholder`}
      />
      {enablePortfolioField && (
        <FormFieldText
          label={__`form-field-linkPortfolio`}
          field={fields.linkPortfolio}
          placeholder={__`form-field-linkPortfolio-placeholder`}
        />
      )}
      <FormFieldFile
        inputLabel={__`form-field-resume-buttonLabel`}
        label={__`form-field-resume`}
        field={fields.resume}
        accept={extensions.map(x => `.${x}`).join(',')}
        required={Boolean(resumeFieldRequired)}
      />
      <FormFieldTextarea
        label={__`form-field-skills`}
        placeholder={__`form-field-text-field-placeholder`}
        field={fields.skillsAmbitions}
      />
    </>
  )
}

function ConsentCheckboxes({ fields }) {
  const { __ } = useTranslate()
  const { params } = useLocationMatch()
  const [toggleAll, setToggleAll] = React.useState(false)

  useUpdateConsentFields(toggleAll)

  return (
    <>
      <FormFieldCheckboxBasic onChange={setToggleAll} value={toggleAll} name='consent-toggle-all'>
        {`${__`application-form-consent-all`} `}
      </FormFieldCheckboxBasic>

      <FormFieldCheckboxConsent field={fields.consentPrivacy} required>
        {`${__`application-form-consent-message-begin`} `}
        <LinkUnderline
          href={routeMap.app.privacyPage({ language: params.language })}
          dataX='link-to-privacyPage'
        >
          {__`form-consent-link-label`}
        </LinkUnderline>
        {` ${__`application-form-consent-message-end`} `}*
      </FormFieldCheckboxConsent>

      <FormFieldCheckboxConsent field={fields.consentCrm}>
        {`${__`application-form-consent-crm-message`} `}
      </FormFieldCheckboxConsent>
    </>
  )

  function useUpdateConsentFields(value) {
    React.useEffect(
      () => {
        fields.consentPrivacy.eventHandlers.onChange(value)
        fields.consentCrm.eventHandlers.onChange(value)
      },
      [value]
    )
  }
}


function Information({ message }) {
  return <p>{message}</p>
}

function SubmitButton({ isLoading, onClick }) {
  const { __ } = useTranslate()

  return (
    <Button type='submit' dataX="submit-application" disabled={isLoading} {...{ onClick }}>
      <span className={styles.componentSubmitButton}>
        {isLoading && <LoaderSmall layoutClassName={styles.loaderLayout} />}
        {__`application-form-send-application`}
      </span>
    </Button>
  )
}

function ValidationErrors({ formOrfield }) {
  const { __ } = useTranslate()
  const { state } = useObjectFormField(formOrfield)
  const snapshot = useFormFieldSnapshot(formOrfield)

  return state.isSubmitted && snapshot.invalid
    ? (
      <p className={styles.componentValidationErrors}>
        {__`form-validation-errors`}
      </p>
    ) : null
}
