import styles from './Loader.css'

export function LoaderSmall({ layoutClassName = undefined }) {
  return <LoaderBase className={styles.componentSmall} {...{ layoutClassName }} />
}

function LoaderBase({ className, layoutClassName }) {
  return (
    <span className={cx(styles.componentBase, className, layoutClassName)} />
  )
}

