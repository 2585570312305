export async function getGoogleAnalyticsId({ ga4Id, identifier }) {
  return new Promise((resolve, reject) => {
    getGaClientId(ga4Id, identifier).then(resolve).catch(reject)
    timeout(1000).catch(reject)
  })
}

async function getGaClientId(ga4Id, identifier) {
  return new Promise((resolve) => {
    gtag('get', ga4Id, identifier, resolve)
  })
}

async function timeout(milliseconds) {
  return new Promise((_, reject) => setTimeout(() => reject(new Error('Timeout obtaining clientId')), milliseconds))
}
