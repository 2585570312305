import { spline } from '@georgedoescode/spline'
import { createNoise3D } from 'simplex-noise'
import { animated, useSpring, useSprings, to, config } from '@react-spring/web'
import { sequence, unlerp, pseudoRandom, randomNumberGenerator } from '@kaliber/math'
import { easeInOutCubic } from '/machinery/easings'

export function BreathingCircles({ width, height }) {
  const interval = 5000
  const breathingIn = useBreathing(interval)
  const { opacity } = useSpring({ from: { opacity: 0 }, to: { opacity: 1 }, delay: 1000, config: config.molasses })
  const [rotations] = useSprings(3, i => ({ from: { rotation: 0 }, to: { rotation: 1 }, loop: true, config: { duration: 30000 + 10000 * i, easing: t => t } }))
  const [springs] = useSprings(
    3,
    () => ({
      radius: breathingIn ? 1 : 0.75,
      config: {
        duration: interval / 3 * 2,
        easing: easeInOutCubic
      },
    }),
    [breathingIn]
  )

  return (
    <svg {...{ width, height }} viewBox={`0 0 ${width} ${height}`}>
      <BreathingCircle opacity={opacity.to(o => o * 0.4)} radius={springs[2].radius} rotation={rotations[2].rotation} {...{ width, height }} />
      <BreathingCircle opacity={opacity.to(o => o * 0.6)} radius={springs[1].radius} rotation={rotations[1].rotation} {...{ width, height }} />
      <BreathingCircle opacity={opacity.to(o => o * 1)} radius={springs[0].radius} rotation={rotations[0].rotation} {...{ width, height }} />
    </svg>
  )
}

function BreathingCircle({ radius, rotation, width, height, opacity }) {
  const maxRadius = Math.min(width, height) / 2
  const seed = React.useId()
  const [simplex3d] = React.useState(() => createNoise3D(randomNumberGenerator(seed)))

  return (
    <animated.path d={to([radius, rotation], radiusToSpline({ simplex3d, width, height, maxRadius }))} fill='none' stroke='currentColor' strokeWidth={1.1} {...{ opacity }} />
  )
}

function useBreathing(ms) {
  const [breathingIn, setBreathingIn] = React.useState(false)

  React.useEffect(
    () => {
      toggleBreathing()

      const interval = setInterval(toggleBreathing, ms)
      return () => clearInterval(interval)

      function toggleBreathing() {
        setBreathingIn(x => !x)
      }
    },
    [ms]
  )

  return breathingIn
}

function radiusToSpline({ simplex3d, width, height, maxRadius }) {
  return (radius, rotation) => {
    const absoluteO = Math.PI * 2 * rotation
    const points = sequence(8)
      .map(n => ({
        o: Math.PI * 2 / 8 * n,
        or: -0.15 * unlerp({ start: -1, end: 1, input: simplex3d(pseudoRandom('offsetRadius'), pseudoRandom(n), 0.0001 * performance.now()) }),
      }))
      .map(({ o, or }) => ({
        x: width / 2 + Math.cos(absoluteO + o) * (maxRadius * (radius + or)),
        y: height / 2 + Math.sin(absoluteO + o) * (maxRadius * (radius + or))
      }))

    return spline(points, 1, true)
  }
}
