import { useEvent } from './useEvent'

export function useWindowEventListener(eventName, fn) {
  const event = useEvent(fn)

  React.useEffect(
    () => {
      window.addEventListener(eventName, event)
      return () => { window.removeEventListener(eventName, event) }
    },
    [event, eventName]
  )
}
