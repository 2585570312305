import { useWindowEventListener } from '/machinery/useWindowEventListener'

const SCREENSAVER_TIMEOUT = 5 * 1000 * 60

let screensaverOpenCallback = null
let screensaverState = { open: false }
let onScreensaverStateChangedCallback = null
let timeoutId = null

export function setOnScreensaverOpen({ callback }) {
  screensaverOpenCallback = callback
}

export function useScreensaverStateChanged() {
  return React.useCallback(
    ({ open }) => {
      screensaverState = { open }
      onScreensaverStateChangedCallback({ open })
    },
    []
  )
}

export function useOpenScreensaver() {
  return React.useCallback(
    () => { screensaverOpenCallback() },
    []
  )
}

export function useScreensaver() {
  onScreensaverStateChangedCallback = ({ open }) => {
    if (!open) restartWaitingForActivity()
    else stopWaitingForInactivity()
  }

  useDebugShowScreensaver()
  useOnActivity({
    callback: () => {
      if (screensaverState.open) return
      restartWaitingForActivity()
    }
  })

  function restartWaitingForActivity() {
    stopWaitingForInactivity()
    timeoutId = setTimeout(
      () => { if (!screensaverState.open) screensaverOpenCallback() },
      SCREENSAVER_TIMEOUT
    )
  }

  function stopWaitingForInactivity() {
    clearTimeout(timeoutId)
  }
}

function useOnActivity({ callback }) {
  useWindowEventListener('scroll', callback)
  useWindowEventListener('mousemove', callback)
}

function useDebugShowScreensaver() {
  useWindowEventListener('keydown', e => {
    if (process.env.NODE_ENV === 'production') return
    if (e.key === 's' && (e.metaKey || e.altKey || e.ctrlKey || e.shiftKey))
      screensaverOpenCallback()
  })
}
