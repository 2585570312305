import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { Form } from './Form'
import { FirebaseAppProvider } from '/machinery/FirebaseAppProvider'
import { useClientConfig } from '/machinery/ClientConfig'

const queryClient = new QueryClient()

export function FormApp({ job, title, enablePortfolioField, utm, isEmployee }) {
  const config = useClientConfig()

  return (
    <FirebaseAppProvider config={config.firebaseConfig} name={config.firebaseConfig.projectId}>
      <QueryClientProvider client={queryClient}>
        <Form {...{ job, title, enablePortfolioField, utm, isEmployee }} />
      </QueryClientProvider>
    </FirebaseAppProvider>
  )
}
