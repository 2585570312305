import { sequence } from '@kaliber/math'
import { useTranslate } from '/machinery/I18n'

import styles from './Stepper.css'

export function Stepper({ step, steps, style = undefined, layoutClassName = undefined }) {
  const { __ } = useTranslate()
  return (
    <div
      style={{ ...style, '--position': step, '--count': 3 }}
      className={cx(styles.component, layoutClassName)}
    >
      {sequence(steps).map(i => (
        <span
          key={i}
          data-key={__`stepper-step`}
          style={{ '--step-label': i + 1 }}
          className={cx(styles.stepElement, step === (i + 1) && styles.isActive)}
        />
      ))}
    </div>
  )
}
