import styles from './Subheading.css'

export function SubheadingSm({ title, layoutClassName = undefined }) {
  return (
    <SubheadingBase
      className={cx(styles.componentSm, styles.uppercase, layoutClassName)}
      {...{ title }}
    />
  )
}

export function SubheadingMd({ title, layoutClassName = undefined }) {
  return (
    <SubheadingBase
      className={cx(styles.componentMd, styles.uppercase, layoutClassName)}
      {...{ title }}
    />
  )
}

export function SubheadingItalicMd({ title, layoutClassName = undefined }) {
  return (
    <SubheadingBase
      className={cx(styles.componentItalicMd, styles.italic, layoutClassName)}
      {...{ title }}
    />
  )
}

export function SubheadingItalicLg({ title, layoutClassName = undefined }) {
  return (
    <SubheadingBase
      className={cx(styles.componentItalicLg, styles.italic, layoutClassName)}
      {...{ title }}
    />
  )
}

function SubheadingBase({ title, className }) {
  return (
    <p
      role="doc-subtitle"
      aria-roledescription="subtitle"
      className={cx(styles.componentBase, className)}
    >
      {title}
    </p>
  )
}
